.loader-container {
}

.loader {
  width: 25px; /* Size of the loader */
  height: 25px; /* Size of the loader */
}

.loader-background,
.loader-progress {
  transition: stroke-dasharray 0.3s ease;
}
