body{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.4;
  color: #333;
  border-color: #E0DEDE;
  overflow: hidden;
}
body img{
  max-width: 100%;
  height: auto;
}
body p{
  font-weight: 300;
  color: #666;
}
.main-section .wrapper{
  padding: 0px 12px;
}
.main-section .border-start, .main-section .border-end, .main-section .border-top, .main-section .border-bottom{
  border-color: #E0DEDE;
}
.main-section .form-control{
  border-color: #E0DEDE;
}
.main-section .chat-box-wrap .form-control:focus{
  z-index: 0;
}
.tab-content:not(.preview-tab) {
text-align: center;
flex: 1;
}
.preview-tab{
  text-align:left;
}
.preview-tab .chat-list-box {
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding-right: 12px;
}
.btn-group-box .btn-group-box-wrap{
  margin-left: 40px;
}
.btn-group-box button {
  display: inline-flex;
  align-items: center;
  justify-content:center;
  color: #FFF;
  text-decoration: none;
  border-radius: 6px;
  background-color: #559aff !important;
  font-weight: 600;
}

.btn-group-box button:hover {
  background-color: #2583ff!important;
}
.for-mobile-only{
  display:none;
}
.copilot-main-section .nav-tabs{
  background-color: #f8fafc;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  border-radius: 6px;
  padding: 6px;
}
.copilot-main-section .nav-tabs .nav-item{
  max-width: 50%;
  width: 100%;
  text-align: center;
}
.copilot-main-section .nav-tabs .nav-item a{
  border-radius: 6px;
  border: 0;
  color: #7a7a7a;
  margin: 0;
}
.copilot-main-section .nav-tabs .nav-item a.active{
  color:#000;
}
.copilot-main-section .form-box{
  overflow: auto;
  height: 78vh;
  padding-right: 12px;
  padding-bottom: 80px;
}
.copilot-main-section .form-box form{
  text-align: left;
}
.copilot-main-section form .input-group span{
  background-color: #fff;
}
.copilot-main-section form .field-set-0 label{
  padding: 50px;
  background-color: #fff;
  border-radius: 50%!important;
  max-width: 142px;
  width: 100%;
  margin: 0 auto!important;
  display: flex;
  justify-content: center;
  border: 1px dashed #dee2e6;
}
.copilot-main-section form .field-set-0 label .fa-plus{
  font-size: 40px;
  line-height: 100%;
}
.copilot-main-section .send-chat-box{
  padding-top: 50px;
}
.copilot-main-section .chat-list-box .left-image-box{
  max-width: 48px;
  width: 100%;
}
.copilot-main-section .chat-list-box .chat-body-box{
  max-width: calc(100% - 48px);
  width: 100%;
  text-align: left;
  /* overflow: hidden; */
}
.copilot-main-section .create-chat-box{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.copilot-main-section #leftTabContent .create-chat-box {
  padding-right: 0px;
}
.copilot-main-section .create-chat-box .chat-list-box{
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding-right: 12px;
}
.copilot-main-section .create-chat-box .chat-list-box.chat-list-box-create-top{
  flex:none!important;
  padding-bottom: 70px
}
.copilot-main-section .chat-list-box-wrap{
  margin-bottom:30px;
}
.copilot-main-section .chat-list-box-wrap p:last-child{
  margin-bottom:0px;
}
.copilot-main-section .chat-box-wrap{
  position: relative;
}
.copilot-main-section textarea{
  resize: none;
}
.copilot-main-section .chat-box-wrap textarea{
  max-height: 200px;
  height: 52px;
  overflow-y: hidden;
  resize: none;
  padding-top: 10px;
  padding-left: 50px;
  border-radius: 12px!important;
}
.copilot-main-section .chat-box-wrap .clip-icon-box{
  position: absolute;
  left: 0px;
  top: 10px;
  z-index: 1;
  background: none;
  border: 0;
}
.copilot-main-section .chat-box-wrap button{
  position: absolute;
  right: 16px;
  top: 11px;
  border-radius: 6px!important;
  background-color: #E0DEDE;
  border-color: #E0DEDE;
}
.copilot-main-section .chat-box-wrap button.active{
  background-color: #569aff;
  border-color: #569aff!important;
}
.copilot-main-section #leftTab1, .copilot-main-section #leftTab3, .copilot-main-section #rightTab1{
  max-width: 100%;
  margin: 0 auto;
}
.copilot-main-section pre{
  padding: 20px;
  text-align: left;
  background-color: #f8fafc;
}
header{
  padding: 12px 0px;
}
header .wrapper{
  padding: 0px 12px;
}
header .left-image-box{
  display: none;
}
header .right-content-box{
  width: 100%;
  text-align: left;
}
header .right-content-box .chat-text-box{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
header .right-content-box .chat-text-box .navbar{
  background-color: transparent!important;
  padding: 0px 0px 0px 0px;
}
header .right-content-box .chat-text-box .navbar button{
  margin-bottom:0px;
  display: block;
  margin-right:8px;
}
header .right-content-box .chat-text-box .navbar button[aria-expanded="true"]{
  display:none;
}
header .right-content-box .chat-text-box h6{
  margin: 0;
  padding: 0 10px 0 0px;
}
header .right-content-box .chat-text-box .dropdown{
  line-height: 1.2;
}
header .right-content-box .chat-text-box .dropdown .dropdown-menu li span{
  opacity:0;
}
header .right-content-box .chat-text-box .dropdown .dropdown-menu li.active span{
  opacity:1!important;
  margin-right:5px;
}
header .right-content-box .chat-text-box .dropdown button{
  background-color: transparent;
    color: #333;
    border: 0;
    padding: 0 0 0 0;
    line-height: 1.2;
}
header .right-content-box .chat-text-box select{
  width: max-content;
}
header .right-content-box p:last-child{
  margin-bottom: 0px;
}
header .right-box .dropdown .dropdown-toggle{
  background-color: transparent;
    border-color: transparent;
    color: #333;
    padding: 0;
}
header .right-box .right-menu-button{
  display: none;
}
.main-section .links-box{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 50px;
}
.main-section ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.main-section ul.bottom li a:hover{
  color: #569AFF;
}
.main-section ul.top li a{
  border-color: #fff;
  padding: 10px 10px 10px 10px;
  margin: 0px 0px -1px 0px;
  border-style: solid;
  border-width: 1px 1px 0px 1px;
  border-radius: 0px 0px 0px 0px;
  display:block;
  color: #569AFF;
  background-color: #fff;
  text-align: left;
  text-decoration: none;
}
.main-section ul.bottom li a{
  border-color: #fff;
  padding: 10px 10px 10px 10px;
  margin: 0px 0px -1px 0px;
  border-style: solid;
  border-width: 1px 1px 0px 1px;
  border-radius: 0px 0px 0px 0px;
  display:block;
  color:#333;
  text-decoration: underline;
  background-color: #fff;
  text-align: left;
}
.copilot-main-section #leftTabContent .send-chat-box{
  padding-right: 12px;
}
.copilot-main-section .tab-content.for-desktop-only{
  padding-left:12px;
}
.main-section  ul.top li a:hover{
  color:#333;
}
.main-section .right-AI-box{
  display: flex;
  flex-direction: column;
}
span.loading{
  line-height:21px;
  font-weight:bold;
  text-align:center;
  width:100px;
  -webkit-animation:fontbulger 2s infinite;
  -moz-animation:fontbulger 2s infinite;
  -o-animation:fontbulger 2s infinite;
  animation:fontbulger 2s infinite;
}
@-webkit-keyframes fontbulger  {
  0%, 100% {
     font-size:25px;
  }
  
  50% {
     font-size:40px;
  }

}

@-moz-keyframes fontbulger  {
  0%, 100% {
     font-size:25px;
  }
  
  50% {
     font-size:40px;
  }

}

@-o-keyframes fontbulger  {
  0%, 100% {
     font-size:25px;
  }
  
  50% {
     font-size:40px;
  }

}

@keyframes fontbulger  {
  0%, 100% {
     font-size:25px;
  }
  
  50% {
     font-size:40px;
  }

}

.custom-button {
  width: fit-content;
  /* margin-top: 30px; */
  background-color: white; /* light grey with transparency */
  color: #333;
  border: 2px solid #ccc; /* subtle border for visual definition */
  border-radius: 0.73rem;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  outline: none;
  position: relative; /* position relative for the arrow */
  overflow: hidden; /* hide the arrow when it's not hovered */
  transition: all 0.3s ease;
  padding-right:  30px;
  top: 30px;
  margin-bottom: 20px;
}

.custom-button:hover {
  background-color: #6c757d; /* slightly darker on hover */
  color: white;
}

.custom-button .arrow {
  font-size: 16px; /* match the font size of the button text */
  opacity: 0; /* arrow is invisible initially */
  transition: opacity 0.3s ease;
  position: absolute;
  right: 5px; /* position the arrow to the right */
  top: 50%;
  transform: translateY(-50%); /* center the arrow vertically */
}

.custom-button:hover .arrow {
  opacity: 1; /* arrow becomes visible on hover */
}




@media (min-width: 960px){
  .main-section .left-AI-box{
      max-width: 260px;
      width: 100%;
      flex-direction: column;
      position: relative;
      display: none;
  }

  .main-section .left-AI-box.show, .main-section .left-AI-box.collapsing{
    display: flex;
  }
  .main-section .left-AI-box .navbar{
    background-color: transparent!important;
    padding: 0px 0px 0px 0px;
    position: absolute;
    right: 6px;
    top: 20px;
  }
  .main-section .left-AI-box .navbar button{
    background-color: transparent;
    border: 0;
    color: #000;
    margin-bottom:0px;
    display: none;
  }
  .main-section .left-AI-box .navbar button[aria-expanded="true"]{
    display: block;
  }
  .main-section .right-AI-box{
      max-width: 100%;
      width: 100%;
      padding-right: 0;
  }
  .main-section .right-AI-box.right-AI-box-w-collspse{
    max-width: calc(100% - 260px);
    width: 100%;
    padding-right: 0;
  } 
}
@media (max-width: 959px){
  header .left-image-box{
      max-width: 200px;
      width: 100%;
      display: block;
  }
  .right-AI-box .right-content-box .chat-text-box .navbar-dark {
    display: none;
  }
  header .right-box{
    justify-content: flex-start!important;
  }
  header .right-box .right-menu-button{
      display: block;
      margin-bottom: 20px;
      background-color: #569aff;
      border-color: #569aff;
  }
  .main-section .left-AI-box{
      display: none;
  }
  .main-section .right-AI-box{
      max-width: 100%;
      width: 100%;
  }
  .main-section .links-box{
    padding: 0!important;
  }
  header .right-content-box .chat-text-box{
    margin-top:20px;
  }
}
@media (max-width: 767px){
  .for-mobile-only{
      display:block;
  }
  .for-desktop-only{
      display:none!important;
  }
  .copilot-main-section .nav-tabs{
      max-width: 100%;
      width: 100%;
  }
  .copilot-main-section .nav-tabs .nav-item{
      max-width: 25%;
      width: 100%;
  }
  .copilot-main-section .nav-tabs .nav-item a{
      padding: 6px;
  }
}
.copilot-main-section .location-form-box .text-fieldset .input-group {
  justify-content: center;
    position: relative;
    flex-direction: column;
}
.copilot-main-section .location-form-box .back-button-box{
  position: absolute;
    left: 0;
    top: 0;
}
.copilot-main-section .location-form-box .back-button-box a{
  width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: inherit;
    border: 1px solid #dee2e6;
    border-radius: 6px;
}
.copilot-main-section .team-form-box form{
  display: flex;
    flex-direction: column;
}
.copilot-main-section .team-form-box form .field-set-4{
  flex: 1;
    position: relative;
}
.copilot-main-section .team-form-box form .field-set-4 button{
  position: absolute;
    bottom: 0;
    right: 0;
} 
.full-height {
  height: 100%;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.pull-right-button{
  width: fit-content;
  float: right;
}
@keyframes heart-beat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.heart-beat {
  animation: heart-beat 1s infinite;
  transition: transform 0.5s ease-in-out;
}
.modal-form-box form button{
  background-color: #569aff;
  border-color: #569aff;
}
.modal .modal-description-box{
  max-height: 200px;
}
.login-modal .forgot-pwd-box a{
  color: #666666;
  text-decoration: none;
}
.login-modal .reset-pwd-box a{
  color: #666666;
  text-decoration: none;
  margin-top: 25px;
  padding-left: 35%;
}
.login-modal .separator-box-main{
  position: relative;
  z-index: 1;
}
.login-modal .separator-box-main .separator-box{
  position: absolute;
    width: 100%;
    height: 1px;
    border: 0.5px solid #666;
    top: 35px;
    z-index: -1;
}
.login-modal .separator-box-main .sep-box-text span{
  padding: 0px 15px;
    background-color: #fff;
}
.login-modal .google-btn-box button{
  background: none;
    color: inherit;
    border-color: #666;
}
.login-modal .bottom-link a{
  text-decoration: none;
}
.login-modal .pvt-box p{
  font-size: 13px;
}
.login-modal .pvt-box p a{
  color: #666666;
}
.circle-plus-btn {
  background-color: lightgray; /* Button's background color */
  color: #7a7a7a; /* Plus sign color */
  font-size: 24px; /* Plus sign size */
  width: 50px; /* Button size */
  height: 50px; /* Button size */
  border-radius: 50%; /* Makes the button circular */
  border: none; /* Removes the default border */
  cursor: pointer;
  display: flex;
  align-items: center; /* Centers the plus sign vertically */
  justify-content: center; /* Centers the plus sign horizontally */
  user-select: none; /* Prevents the plus sign from being selected */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.circle-plus-btn:hover {
  background-color: #e0e0e0; /* Lighter shade for hover background */
  color: #5a5a5a; /* Darker shade for hover text color */
}
.copilot-main-section .chat-list-box .chat-body-box-v2{
  overflow: initial;
}
.copilot-main-section .chat-list-box .chat-body-box-v2 .dropdown-toggle{
  padding-top:0px;
  padding-bottom:0px;
}
.copilot-main-section .chat-list-box .chat-body-box-v2 h6{
  margin-bottom:0;
  margin-left:12px;
}
.create-chat-box .chat-text-box .dropdown .dropdown-menu li span{
  opacity:0;
}
.create-chat-box .chat-text-box .dropdown .dropdown-menu li.active span{
  opacity:1!important;
  margin-right:5px;
}
.splash-wrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  text-align:center;
  transform: translateX(-50%);
}

.splash-spanner{
  position:absolute;
  left: 0;
  background: #2a2a2a55;
  width: 100%;
  display:block;
  text-align:center;
  height: 300px;
  color: #FFF;
  z-index: 1000;
  visibility: hidden;
  height: 100%;
}

.splash-overlay{
  position: fixed;
	width: 100%;
	height: 100%;
  background: #2a2a2a55;
  
}

.splash-loader,
.splash-loader:before,
.splash-loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.splash-loader {
  color: #ffffff;
  font-size: 10px;
  margin-left: 50%;
  margin-top: 28%;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.splash-loader:before,
.splash-loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.splash-loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.splash-loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.splash-show{
  visibility: visible;
}

.splash-spanner, .splash-overlay{
	opacity: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.splash-spanner.splash-show, .splash-overlay.show {
	opacity: 1
}

/*widget preview css*/

.widgetgpt-main-section {
  max-width: 448px;
  width: 100%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 8px 10px -6px;
  border-radius: 10px;
  overflow: hidden;
  height: 85vh;
  position: fixed;
  right: 1rem;
  z-index: 9999999;
  bottom: 5rem;
  background: #fff;
}

.widgetgpt-container {
  height: 100%;
}

.widgetgpt-container button {
  background-color: transparent;
  background-image: none;
  border: 0;
}

.widgetgpt-container nav a {
  gap: .25rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.widgetgpt-container .flex-1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.widgetgpt-container .shrink-0 {
  flex-shrink: 0;
}

.widgetgpt-container header {
  background-color: var(--primary-color);
}

.widgetgpt-container header .widgetgpt-header-center-content {
  font-weight: 500;
}

.widgetgpt-container .widgetgpt-nav-section a {
  font-size: 12px;
}

.widgetgpt-container .widgetgpt-home-content .title-home {
  font-size: 20px;
}

.widgetgpt-container .widgetgpt-home-content .title-home,
.widgetgpt-container .widgetgpt-home-content .widgetgpt-placeholder {
  margin-bottom: 20px;
}

.widgetgpt-container .cta-widget {
  background-color: var(--primary-color);
  color: #FFF;
  text-decoration: none;
  border-radius: 6px;
}

.widgetgpt-container .cta-widget:hover {
  background-color: var(--cta-hover-color);
}

.widgetgpt-container .widgetgpt-conversation-body-section .cta-widget {
  font-size: .875em;
}

.widgetgpt-container .widgetgpt-nav-section a {
  text-decoration: none;
  color: inherit;
}

.widgetgpt-container .widgetgpt-nav-section a:hover {
  color: var(--primary-color);
}

.widgetgpt-container .widgetgpt-nav-section a svg.hover-icon {
  display: none;
}

.widgetgpt-container .widgetgpt-nav-section a:hover svg.hover-icon {
  display: inline-block;
}

.widgetgpt-container .widgetgpt-nav-section a:hover svg.normal-icon {
  display: none;
}

.widgetgpt-container .widgetgpt-message-content-link {
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid #dee2e6;
}

.widgetgpt-container .widgetgpt-message-content-link:hover {
  background-color: rgb(249 250 251 / 1);
}

.widgetgpt-container .widgetgpt-message-content-link:last-child {
  border-bottom: none;
}

.widgetgpt-container .message-text-box p {
  text-decoration: none;
  color: inherit;
  margin-bottom: 8px;
}

.widgetgpt-container .message-text-box .message-text-bottom {
  font-size: 14px;
}

.widgetgpt-container .widgetgpt-message-content.pb-20 {
  padding-bottom: 5rem;
}

.widgetgpt-container .widgetgpt-message-button-overlay.h-24 {
  height: 6rem;
}

.widgetgpt-container .widgetgpt-message-button-overlay {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(255, 255, 255));
}

.widgetgpt-container .widgetgpt-message-button {
  pointer-events: none;
}

.widgetgpt-container .widgetgpt-message-button a {
  pointer-events: auto;
}

.widgetgpt-container .widget-conversation textarea {
  padding: 10px 96px 10px 40px;
  overflow-y: hidden;
  border-radius: 12px !important;
  border-color: #E0DEDE;
  border-style: solid;
  border-width: 1px;
}

.widgetgpt-container .widget-conversation textarea:focus {
  outline: 2px solid var(--primary-color);
}

.widgetgpt-container .widget-conversation .button-box {
  right: 2px;
  top: 8px;
}

.widgetgpt-container .widget-conversation .button-box button {
  border: 0 !important;
  border-radius: 6px;
}

.widgetgpt-container .widget-conversation .button-box button:hover {
  background-color: var(--primary-color);
}

.widgetgpt-container .widget-conversation button svg {
  width: 20px;
  height: 20px;
}

.widgetgpt-container .widget-conversation button:hover svg {
  fill: #FFF;
}

.widgetgpt-container .widgetgpt-conversation-message {
  background-color: rgb(249 250 251 / 1);
  border-radius: .75rem;
}

.widgetgpt-container .user-message .widgetgpt-conversation-message {
  background-color: #569AFF;
  color: #FFF;
}

.widgetgpt-container .bot-message .bot-name-details {
  max-width: calc(100% - 42px);
  width: 100%;
  margin-left: auto;
  display: block;
}

.widgetgpt-container .widgetgpt-conversation-link-item {
  border-color: #E0DEDE;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
}

.widgetgpt-container .widgetgpt-conversation-link-item p {
  margin: 0;
}

.widgetgpt-container .widgetgpt-conversation-link-item p a {
  text-decoration: none;
}

.widgetgpt-container .widget-rating-box svg {
  width: 16px;
  height: 16px;
}

.widgetgpt-container .widget-rating-box button {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.45);
}

.widgetgpt-container .widget-rating-box button.pov-button:hover {
  background-color: rgb(240 253 244 / 1);
}

.widgetgpt-container .widget-rating-box button.neg-button:hover {
  background-color: rgb(254 242 242 / 1);
}

.widgetgpt-container .widget-rating-box button.pov-button:hover svg {
  fill: #28a745;
}

.widgetgpt-container .widget-rating-box button.neg-button:hover svg {
  fill: #dc3545;
}

.widgetgpt-container .widgetgpt-conversation-button-box {
  right: 0;
  top: calc(50% - 10px);
}

.widgetgpt-container .widgetgpt-conversation-form form input {
  border-radius: 6px;
}

.widgetgpt-container .clip-icon-box {
  position: absolute;
  left: 0;
  top: 10px;
  z-index: 1;
  background: none;
  border: 0;
}

.widgetgpt-container .back-icon {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.widgetgpt-container .refresh-button svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.widgetgpt-container .mute-button svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.widgetgpt-container button img {
  width: 16px;
  height: auto;
}

.widgetgpt-container button {
  min-width: 30px;
  margin-bottom: 0px;
  margin-top: 0px;
  top: 0px;
}

.widgetgpt-main-section .widgetgpt-contact-two-col-text img {
  margin-right: 20px;
}

.widgetgpt-main-section .widgetgpt-contact-two-col-text .form-lead-text {
  width: calc(100% - 60px);
  text-align: left;
}

.widgetgpt-container .widgetgpt-contact-form-box button {
  background-color: var(--primary-color);
  max-width: 250px;
  width: 100%;
}

.widgetgpt-container .widgetgpt-contact-form-box input {
  height: 36px;
}

.widgetgpt-container .widgetgpt-nav-section .nav-icon-box {
  height: 26px;
  width: 26px;
}

.widgetgpt-container .widgetgpt-nav-section .widget-alert {
  width: 20px;
  height: 20px;
  line-height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: 0 !important;
  padding: 3px !important;
  font-size: 10px;
  top: 3px !important;
}

.widgetgpt-container .widgetgpt-nav-section a.active {
  color: var(--primary-color);
}

.widgetgpt-container .widgetgpt-nav-section a.active .widget-alert {
  display: flex;
}

.widgetgpt-container .widgetgpt-nav-section a.active svg.hover-icon {
  display: inline-block;
}

.widgetgpt-container .widgetgpt-nav-section a.active svg.normal-icon {
  display: none;
}

@media (max-width: 622px) {
  .widgetgpt-main-section {
    right: 0px;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}